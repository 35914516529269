/* Variables */

$primary:                     #ee4b81;

$body-bg:                     #1D1F2D;
$body-color:                  #DDDDDD;
$font-family-base:            Poppins, sans-serif;
$font-weight-base:            400;
$line-height-base:            1.8;
$form-select-indicator-color: white;
$link-decoration:             none;

$container-max-widths:        (sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1440px);

$accordion-border-color:      #454545;
$accordion-bg:                transparent;
$accordion-button-active-bg:  transparent;

$offcanvas-bg-color:          $body-bg;

:root {
    --bs-primary: $primary;
}

@import url('https://fonts.googleapis.com/css?family=Poppins');
@import '../node_modules/bootstrap/scss/bootstrap';
@import "assets/styles/rebootstrap";
@import "assets/styles/global";

// searchbar fixes
.jso-dropdown.jso-quantity-selector-dropdown.jso-fade {
    display: relative !important;
    left: 0;
    top: 99px;
}

.tabsDate{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tabs{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab-title{
    color: #FFFFFF;
    padding: 12px 24px;
    font-size: 16px;
    font-family: 'Montserrat';
    cursor: pointer;
    background-color: #1B2540;
}
.tab-title-active{
    color: #1B2540;
    padding: 12px 24px;
    font-size: 16px;
    font-family: 'Montserrat';
    cursor: pointer;
    background-color: #FFFFFF;
}
